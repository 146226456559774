import {
  ViewerObject,
  ViewerObjectMap,
  ViewOrientation,
} from "@promaton/scan-viewer";
import { minimatch } from "minimatch";
import { Matrix4 } from "three";
import { z } from "zod";

import { TypeToZod } from "./types/TypeToZod";

const VIEWER_CONFIG_FILE = /viewerconfig(\.json)?$/i;

/** Partial {@link ViewerObject} converted to zod schema for runtime validation. */
const zodViewerObject: Omit<
  TypeToZod<ViewerObject>,
  "url" | "objectType" | "side" | "customMaterial" | "geometry" | "disabled"
> = {
  color: z.string().refine((value) => /^#[0-9A-Fa-f]{6}$/.test(value)),
  dirty: z.boolean(),
  opacity: z.number().min(0).max(1),
  hidden: z.boolean(),
  group: z.string(),
  subGroups: z.array(z.string()),
  excludeInOrientations: z.array(z.nativeEnum(ViewOrientation)),
  excludeInViews: z.array(z.string()),
  transform: z
    .array(z.number())
    .length(16)
    .transform((v) => new Matrix4().fromArray(v)),
  clipToPlanes: z.boolean(),
  renderVolume: z.boolean(),
  isMetadata: z.boolean(),
  renderOrder: z.number().int(),
  depthOffsetFactor: z.number().int(),
  depthWrite: z.boolean(),
  roughness: z.number().min(0).max(1),
  metalness: z.number().min(0).max(1),
  vertexColors: z.boolean(),
  flatShading: z.boolean(),
  showAxis: z.enum(["x", "y", "z"]),
  crossSectionAlongCurve: z.boolean(),
  detectOrientation: z.boolean(),
  showMeshInPanorama: z.boolean(),
};

const viewerConfigSchema = z.object({
  /** Override default styles for objects matching a given glob pattern. */
  objectStyles: z.record(z.object(zodViewerObject).partial()).optional(),
});

/**
 * Apply styles from viewerConfig.json files to the objects.
 *
 * viewerConfig.json files in subfolders only apply to objects in their folder
 * and their styles take precedence over ones from parent folders.
 */
export const applyViewerConfig = async (objectMap: ViewerObjectMap) => {
  const lengthSortedConfigPaths = Object.keys(objectMap)
    .filter((path) => VIEWER_CONFIG_FILE.test(path.split("/").at(-1) ?? ""))
    .sort((a, b) => dirname(a).length - dirname(b).length);

  const configs = await Promise.all(
    lengthSortedConfigPaths
      .filter((path) => typeof objectMap[path]?.url === "string")
      .map(
        async (path) =>
          [
            path,
            await fetchConfigObject(objectMap[path]!.url as string),
          ] as const
      )
  );

  for (const [configPath, config] of configs) {
    if (!config?.objectStyles) {
      continue;
    }

    const dirPath = dirname(configPath);
    for (const [glob, styles] of Object.entries(config.objectStyles)) {
      for (const [path, object] of Object.entries(objectMap)) {
        if (
          path.startsWith(dirPath) &&
          minimatch(path, glob, {
            nocase: true,
          })
        ) {
          Object.assign(object, styles);
        }
      }
    }
  }
};

function dirname(path: string) {
  return path.split("/").slice(0, -1).join("/");
}

/** Fetch and parse a viewer config object by URL. */
async function fetchConfigObject(url: string) {
  const unparsedConfig = await fetch(url).then((response) => response.json());
  const config = await viewerConfigSchema.parseAsync(unparsedConfig);

  Object.assign(config, {
    isMetadata: true,
  });

  return config;
}
