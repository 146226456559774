import { zodResolver } from "@hookform/resolvers/zod";
import {
  Alert,
  Button,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { cbct } from "@promaton/api-client";
import { createNotification } from "@promaton/frontend-common";
import { AlertCircle } from "@promaton/icons";
import { FileType, useObjects, ViewerObject } from "@promaton/scan-viewer";
import { AxiosResponse } from "axios";
import { FC, memo, useMemo, useState } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";

import { createAiTaskMetadataPayload } from "../helpers/ai/aiTaskMetadata";
import { useAiAssistantHeaders } from "../hooks/useAiAssistantHeaders";
import { useIsDarkMode } from "../hooks/useIsDarkMode";
import {
  AI_TASK_RETENTION_TIME_MINUTES,
  useAiAssistantState,
} from "../stores/useAiAssistantState";
import { LocalFilePickerButton } from "./LocalFilePickerButton";

export const getCbctCommonTaskDataUpload = async (
  id: string,
  cbctObject: ViewerObject
) => {
  const jszip = await import("jszip");
  const zip = new jszip.default();

  const urls = Array.isArray(cbctObject.url)
    ? cbctObject.url
    : [cbctObject.url];

  await Promise.all(
    urls.map(async (url, index) => {
      const file = await (await fetch(url)).blob();
      zip.file<"blob">(
        `${id.split("/").at(-1)}${index.toString().padStart(4, "0")}.${
          FileType.DICOM
        }`,
        file
      );
    })
  );

  const blob = await zip.generateAsync({ type: "blob" });
  return blob;
};

export const AiAssistantCbctCommonFormSchema = z.object({
  dicomId: z.string().min(1),
  runImplantsAndCrowns: z.boolean().optional(),
  runFilledJaw: z.boolean().optional(),
});

export type AiAssistantCbctCommonFormData = z.infer<
  typeof AiAssistantCbctCommonFormSchema
>;

interface AiAssistantCbctCommonFormProps {
  schema: z.ZodSchema<AiAssistantCbctCommonFormData>;
  defaultValues: Record<string, boolean | string | undefined>;
  mutation: typeof cbct.useCreateCbct | typeof cbct.useCreateCbctFilledJaw;
  onSubmitSuccess: (res: AxiosResponse) => void;
  refreshData: () => void;
  formFields: FC<{ disabled?: boolean }>;
  title: string;
  description: string;
  imageSrc: string;
  buttonText: string;
  note?: string;
}

export const AiAssistantCbctCommonAlert: FC = memo(() => {
  return (
    <Alert
      color="info"
      icon={<AlertCircle />}
      action={<LocalFilePickerButton accept=".dcm" />}
      sx={{ alignItems: "center" }}
    >
      Drop or select DICOM file of a CBCT scan to process
    </Alert>
  );
});

export const AiAssistantCbctCommonForm: FC<AiAssistantCbctCommonFormProps> =
  memo(
    ({
      schema,
      defaultValues,
      mutation,
      onSubmitSuccess,
      refreshData: invalidateQuery,
      formFields: FormFields,
      title,
      description,
      imageSrc,
      buttonText,
      note,
    }) => {
      const isDark = useIsDarkMode();
      type FormData = z.infer<typeof schema>;
      const form: UseFormReturn<FormData> = useForm<FormData>({
        resolver: zodResolver(schema),
        defaultValues,
      });

      const setActivePage = useAiAssistantState((s) => s.setActivePage);

      const [loading, setLoading] = useState(false);
      const [progress, setProgress] = useState(0);

      const headers = useAiAssistantHeaders({
        "Content-Type": "application/zip",
      });

      const mutationInstance = mutation({
        axios: {
          ...headers?.axios,
          onUploadProgress: (e) => {
            setProgress((e.progress ?? 0) * 100);
          },
        },
        mutation: {
          onSuccess: () => invalidateQuery(),
        },
      });

      const handleSubmit = useMemo(() => {
        return form.handleSubmit(async (data) => {
          const dicomId = data.dicomId;
          const runImplantsAndCrowns = data.runImplantsAndCrowns;
          const runFilledJaw = data.runFilledJaw;

          try {
            const cbctObject = data.dicomId
              ? useObjects.getState().objects[dicomId]
              : undefined;
            if (!cbctObject) return;
            setLoading(true);

            const res = await mutationInstance.mutateAsync({
              data: await getCbctCommonTaskDataUpload(dicomId, cbctObject),
              params: {
                meta_data: createAiTaskMetadataPayload({ name: dicomId }),
                retain: AI_TASK_RETENTION_TIME_MINUTES,
                "run-implants-and-crowns": runImplantsAndCrowns,
                "run-filled-jaw": runFilledJaw,
              },
            });
            onSubmitSuccess(res);
            setActivePage(null);
          } catch (error) {
            console.error(error);
            createNotification({
              color: "error",
              text: `Failed to start ${title.toLowerCase()}`,
            });
          }
          setLoading(false);
        });
      }, [mutationInstance]);

      return (
        <Stack gap={1} pt={2}>
          <img
            alt={`${title} icon`}
            style={{ width: 150 }}
            src={isDark ? `/${imageSrc}/dark.svg` : `/${imageSrc}/light.svg`}
          />
          <Typography variant="h6" mt={2}>
            {title}
          </Typography>
          <Typography variant="body1" mb={1}>
            {description}
          </Typography>
          <Typography
            variant="body2"
            mb={2}
            fontStyle="italic"
            sx={{ opacity: 0.7 }}
          >
            Segmentation may take between 30 seconds and 10 minutes, or longer
            depending on your internet speed. <br />
            {note}
          </Typography>
          <form onSubmit={handleSubmit}>
            <Stack gap={2}>
              <FormProvider {...form}>
                <FormFields disabled={loading} />
              </FormProvider>
              <Button
                size="large"
                type={"submit"}
                disabled={loading || !form.formState.isValid}
                variant="contained"
                sx={{ overflow: "hidden" }}
              >
                {loading && (
                  <LinearProgress
                    value={progress}
                    variant={progress ? "determinate" : "indeterminate"}
                    color="inherit"
                    sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
                  />
                )}
                {mutationInstance.isLoading ? "Uploading..." : buttonText}
              </Button>
            </Stack>
          </form>
        </Stack>
      );
    }
  );
