import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { FolderAlt } from "@promaton/icons";
import { FC, memo } from "react";
import { Link, useRoute } from "wouter";
import { useShallow } from "zustand/shallow";

import { Favorite, useRecents } from "../stores/useRecents";

export const FavoriteList: FC<{}> = memo(() => {
  const favorites = useRecents(
    useShallow((s) =>
      Object.values(s.favorites).sort((a, b) => a.title.localeCompare(b.title))
    )
  );

  if (!favorites?.length) return null;

  return (
    <List>
      <ListSubheader sx={{ background: "transparent" }}>
        Favorites
      </ListSubheader>
      {favorites.map((favorite) => {
        return <FavoriteListItem favorite={favorite} key={favorite.path} />;
      })}
    </List>
  );
});

const FavoriteListItem = ({ favorite }: { favorite: Favorite }) => {
  const link = `/browse/${favorite.path}`;
  const [active] = useRoute(link);
  return (
    <ListItem dense key={favorite.path}>
      <Link href={link}>
        <ListItemButton selected={active} sx={{ borderRadius: 1 }} href={link}>
          <FolderAlt sx={{ marginRight: 2 }} />
          <ListItemText
            primary={favorite.title}
            primaryTypographyProps={{
              sx: {
                width: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              },
            }}
          />
        </ListItemButton>
      </Link>
    </ListItem>
  );
};
