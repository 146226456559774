import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { createNotification } from "@promaton/frontend-common";
import {
  CoordinateSystem,
  useObjects,
  useStaging,
  ViewerObject,
  ViewerObjectMap,
} from "@promaton/scan-viewer";
import { FC, memo, useEffect } from "react";
import { Matrix4 } from "three";
import { useLocation } from "wouter";
import { useShallow } from "zustand/shallow";

import { trpc } from "../hooks/trpc";
import { useAppState } from "../stores/useAppState";
import { useRecents } from "../stores/useRecents";
import { LoadingMetric } from "./LoadingMetric";
import { LoginDialog } from "./LoginDialog";

export const SceneLoader: FC<{ id: string }> = memo(({ id }) => {
  const [location, setLocation] = useLocation();
  const session = useAppState((s) => s.session);
  const scene = trpc.scene.get.useQuery(id, {
    enabled: !!session,
  });
  const setObjects = useObjects((s) => s.setObjects);
  const setCoordinateSystem = useStaging((s) => s.setCoordinateSystem);
  const addRecent = useRecents((s) => s.addRecent);
  const updateThumbnail = trpc.scene.updateThumbnail.useMutation();
  const utils = trpc.useContext();

  useEffect(() => {
    if (scene.data) {
      const data = { ...scene.data.data } as ViewerObjectMap;
      Object.keys(data).map((key) => {
        const obj = { ...data[key] };
        if (obj.transform) {
          obj.transform = new Matrix4().fromArray(obj.transform.elements);
        }

        if (!obj.url) return;
        data[key] = obj as ViewerObject;
      });

      const coordinateSystem = scene.data.coordinateSystem;
      setCoordinateSystem(
        coordinateSystem === CoordinateSystem.IJK
          ? CoordinateSystem.IJK
          : CoordinateSystem.LPS
      );

      setObjects(data);
      addRecent({
        title: scene.data.name,
        path: location,
        date: new Date(),
      });
    } else if (scene.error || (!scene.data && scene.isSuccess)) {
      createNotification({ color: "error", text: "Unable to load scene" });
      setLocation("/");
    }
  }, [scene.data, scene.error]);

  const recentWithScreenshot = useRecents(
    useShallow((s) => {
      const last = s.recents.at(-1);

      if (last?.path === location && last.screenshot) {
        return last;
      }
    })
  );

  useEffect(() => {
    if (recentWithScreenshot && scene.data && !scene.data.thumbnail) {
      updateThumbnail
        .mutateAsync({
          id,
          thumbnail: recentWithScreenshot.screenshot,
        })
        .then(() => {
          utils.scene.list.invalidate();
        });
    }
  }, [recentWithScreenshot, scene.data]);

  if (!session) {
    return (
      <LoginDialog
        title="Log in to load scene"
        onClose={() => {
          setLocation("/");
        }}
        open
      />
    );
  }

  if (scene.isLoading) {
    return (
      <Dialog open>
        <DialogContent
          sx={{ display: "flex", alignItems: "center", gap: 2, padding: 4 }}
        >
          <CircularProgress />
          <DialogContentText>Loading scene...</DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  return <LoadingMetric />;
});
