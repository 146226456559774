import {
  AppBar,
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Container,
  IconButton,
  Link as MLink,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  styled,
  Toolbar,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { Properties } from "@promaton/icons";
import { FC } from "react";
import { Link } from "wouter";

import { createRouteLink, routes } from "../helpers/routes";
import { useIsDarkMode } from "../hooks/useIsDarkMode";
import { useIsMobile } from "../hooks/useIsMobile";
import { AiTaskType, useAiAssistantState } from "../stores/useAiAssistantState";
import { useAppState } from "../stores/useAppState";
import { AddBanner } from "./AddBanner";
import { AiAssistant } from "./AiAssistant";
import { APICard, APIIcon } from "./AiAssistantRootPage";
import { Example } from "./PublicExampleLoader";

export const PublicRootPage: FC<{ onOpenFiles: (files: FileList) => void }> = ({
  onOpenFiles,
}) => {
  const setActiveAiPage = useAiAssistantState((s) => s.setActivePage);
  const setAiDialogOpen = useAiAssistantState((s) => s.setDialogOpen);
  const isDark = useIsDarkMode();
  const mobile = useIsMobile();
  const showSettings = useAppState((s) => s.showSettings);

  const logo = (
    <ListItem
      sx={{
        marginTop: 2,
        padding: 0,
        paddingLeft: 0,
        marginBottom: 3,
        position: "sticky",
        top: 0,
        zIndex: 1,
        background: "inherit",
      }}
    >
      <ListItemAvatar>
        <Avatar
          src="/promaton-logo.png"
          imgProps={{
            draggable: false,
          }}
          sx={{
            filter: isDark ? undefined : `brightness(0)`,
          }}
        />
      </ListItemAvatar>
      <ListItemText
        primary="Promaton Viewer"
        primaryTypographyProps={{
          variant: "h6",
          fontWeight: "medium",
        }}
      />
    </ListItem>
  );

  return (
    <Stack>
      <AppBar
        color="inherit"
        variant="outlined"
        position="sticky"
        sx={{
          border: "none",
          paddingTop: 4,
          top: (t) => `-${t.spacing(3)}`,
          background: "transparent",
          backdropFilter: "blur(10px)",
        }}
      >
        <Toolbar sx={{ padding: "0 !important" }}>
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {logo}

            <IconButton size="large" onClick={() => showSettings(true)}>
              <Properties />
            </IconButton>
          </Container>
        </Toolbar>
      </AppBar>

      <Sphere />
      <Sphere
        sx={{
          background: (t) => t.palette.primary.main,
          right: "30vh",
          top: "-50vh",
        }}
      />
      <Sphere
        sx={{
          background: (t) => t.palette.warning.main,
          left: "0vh",
          right: "initial",
          top: "-40vh",
          animationDelay: "2s",
        }}
      />

      <Container>
        <Stack sx={{ gap: 2, paddingY: 3 }}>
          <Stack sx={{ marginBottom: 3, gap: 1 }}>
            <Typography variant="h6">
              A web-based 3D viewer tool to visualize and inspect medical images
              (CBCT) and 3D models.
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 3 }}>
              Note: Promaton Viewer is not intended for clinical use.
            </Typography>
          </Stack>
          <AddBanner
            onOpenFiles={onOpenFiles}
            sx={{
              flex: 0,
              padding: 4,
              marginBottom: 3,
            }}
          />

          <Stack flexDirection={"row"} alignItems={"flex-end"}>
            <Stack flex={1}>
              <Typography variant="h6" fontWeight={"medium"}>
                Try Promaton AI
              </Typography>
              <Typography variant="body2" fontWeight={"medium"}>
                Use our APIs for dental treatment planning automations, in the
                browser.{" "}
                <MLink
                  underline="hover"
                  color="primary"
                  target="_blank"
                  href="https://www.promaton.com/contact"
                >
                  Contact us
                </MLink>{" "}
                to request an API key.
              </Typography>
            </Stack>
            <AiAssistant
              buttonProps={{
                color: "secondary",
                variant: "outlined",
                sx: { flexDirection: "row", gap: 1, borderRadius: 1 },
              }}
            />
          </Stack>

          <Card
            variant="outlined"
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
              padding: 3,
              marginY: 1,
              marginBottom: 3,
              gap: 3,
              flexWrap: "wrap",
              background: (t) => t.palette.action.hover,
              borderRadius: 2,
              ["& button"]: {
                width: 200,
              },
              ["& img"]: {
                marginBottom: 2,
                marginTop: 0,
              },
            }}
          >
            <APICard
              disableRipple
              onClick={() => {
                setActiveAiPage(AiTaskType.OPTICAL);
                setAiDialogOpen(true);
              }}
            >
              <APIIcon src={isDark ? "/oss/dark.svg" : "/oss/light.svg"} />
              <Typography variant="body2">Optical Scan Segmentation</Typography>
            </APICard>
            <APICard
              disableRipple
              onClick={() => {
                setActiveAiPage(AiTaskType.CBCT);
                setAiDialogOpen(true);
              }}
            >
              <APIIcon src={isDark ? "/cbct/dark.svg" : "/cbct/light.svg"} />
              <Typography variant="body2">CBCT Segmentation</Typography>
            </APICard>
            <APICard
              disableRipple
              onClick={() => {
                setActiveAiPage(AiTaskType.ALIGNMENT);
                setAiDialogOpen(true);
              }}
            >
              <APIIcon
                src={isDark ? "/fusion/dark.svg" : "/fusion/light.svg"}
              />
              <Typography variant="body2">Alignment &amp; Fusion</Typography>
            </APICard>
            <APICard
              disableRipple
              onClick={() => {
                setActiveAiPage(AiTaskType.ACD);
                setAiDialogOpen(true);
              }}
            >
              <APIIcon src={isDark ? "/acd/dark.svg" : "/acd/light.svg"} />
              <Typography variant="body2">Automatic Crown Design</Typography>
            </APICard>
            {/* <APICard
              disableRipple
              onClick={() => {
                setActiveAiPage(AiTaskType.TSC);
                setAiDialogOpen(true);
              }}
            >
              <APIIcon src={isDark ? "/tsc/dark.png" : "/tsc/light.png"} />
              <Typography variant="body2">Tooth Shape Completion</Typography>
            </APICard> */}
            <APICard
              disableRipple
              onClick={() => {
                setActiveAiPage(AiTaskType.SIPP);
                setAiDialogOpen(true);
              }}
            >
              <APIIcon src={isDark ? "/sipp/dark.png" : "/sipp/light.png"} />
              <Typography variant="body2">
                Implant Placement Planning
              </Typography>
            </APICard>
            <APICard
              disableRipple
              onClick={() => {
                setActiveAiPage(AiTaskType.CBCT_FILLED_JAW);
                setAiDialogOpen(true);
              }}
            >
              <APIIcon src={isDark ? "/jaw/dark.svg" : "/jaw/light.svg"} />
              <Typography variant="body2">Jaw-Hole Filling Service</Typography>
            </APICard>
          </Card>

          <Stack mt={2}>
            <Typography variant="h6" fontWeight={"medium"}>
              Sample Outputs
            </Typography>
            <Typography variant="body2" fontWeight={"medium"}>
              Check out examples of the input and output of our segmentation{" "}
              <MLink
                underline="hover"
                color="primary"
                target="_blank"
                href="https://www.promaton.com/solutions"
              >
                AI models
              </MLink>
              !
            </Typography>
          </Stack>

          <Grid
            container
            spacing={3}
            padding={2}
            sx={{
              width: "100%",
              marginLeft: 0,
              border: (t) => `${t.palette.divider} 1px solid`,
              marginY: 1,
              background: (t) => t.palette.action.hover,
              borderRadius: 2,
            }}
          >
            <ExampleCard
              title="Segmented CBCT"
              description={`An example of a segmented 3D model based on a CBCT scan${
                mobile
                  ? ". Note: CBCT is not loaded on mobile for performance and data reasons."
                  : ""
              }`}
              thumbnail="/cbct_thumb.png"
              href={createRouteLink(routes.example, { id: Example.CBCT })}
            />
            <ExampleCard
              title="Segmented optical scan"
              description="A segmented 3D model based on an interoral (optical) scan"
              thumbnail="/oss_thumb.png"
              href={createRouteLink(routes.example, { id: Example.IOS })}
            />
            <ExampleCard
              title="Automatic Crown Design"
              description="Crowns generated to fit in a segmented interoral scan"
              thumbnail="/acd_thumb.png"
              href={createRouteLink(routes.example, { id: Example.ACD })}
            />
            <ExampleCard
              title="Tooth Shape Completion"
              description="Closing tooth geometry from a segmented interoral scan"
              thumbnail="/completion_thumb.png"
              href={createRouteLink(routes.example, { id: Example.COMPLETION })}
            />
          </Grid>
        </Stack>
      </Container>
    </Stack>
  );
};

const ExampleCard: FC<{
  thumbnail: string;
  href: string;
  title: string;
  description: string;
}> = ({ title, description, href, thumbnail }) => {
  return (
    <Grid xs={12} sm={6} md={4}>
      <Link href={href}>
        <Card
          variant="elevation"
          elevation={0}
          sx={{
            borderRadius: 2,
            cursor: "pointer",
            background: "none",
            overflow: "visible",

            ["&:hover"]: {
              ["img"]: {
                background: (t) => t.palette.divider,
                boxShadow: `0px 10px 20px -5px rgba(0, 0, 0, 0.15)`,
              },
            },
          }}
        >
          <CardMedia
            component="img"
            height={220}
            draggable={false}
            src={thumbnail}
            sx={{
              transition: "all 0.25s ease",
              borderRadius: 2,
              boxShadow: `0px 5px 15px -3px rgba(0, 0, 0, 0.15)`,
              border: (t) => `1px solid ${t.palette.divider}`,
            }}
          />

          <CardContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body2">{description}</Typography>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
};

const Sphere = styled("div")`
  z-index: -1;
  mix-blend-mode: hard-light;
  position: absolute;
  right: -10vh;
  top: -30vh;
  height: 60vh;
  width: 60vh;
  border-radius: 100%;
  background: ${({ theme }) => theme.palette.secondary.main};
  filter: blur(200px);
  opacity: 0.25;
`;
