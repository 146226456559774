/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Promaton HTTP API
 * This API allows you to upload STL and 3D CBCT images for segmentation.

## Tasks
Because inferences take longer than some proxy servers allow open
connection for, we accept all uploads as a `task` which you
can monitor until it reaches completion.

Monitoring can be done via polling.

## Authentication

Authentication is done by setting your api key in the `Authorization`
header, prefixed with `Bearer`, like so: `Bearer <yourtoken>`.

Depending on your API token, you can have access to either OSS, CBCT,
ACD or all.

## Uploading your first STL
You can upload your first STL using the following curl command:

```sh
curl -X POST "https://api.promaton.com/oss/lower" \
    -H "Content-Type: model/stl" \
    --data-binary "@path_to_stl.lower.stl" \
    --header "Authorization: Bearer <your token>" \
    --http1.1 --verbose \
```

After that, you can get the status of your task using
the id of the task returned to you, in the following
curl command:

```sh
curl "https://api.promaton.com/oss/{your task id}" \
    --header "Authorization: Bearer <your token>" \
    --http1.1\
```

Until it reaches the completed state.

You can then use either the `stl` output, or `polyline` output endpoints
to get the result. If an error occurs, the state will switch to `errored`.

## Response times

We shut down our servers whenever possible to reduce cost. You therefore
may experience a "cold start" when first calling our API. This takes
at most five minutes. After that, the response times should be on par.

## Task pagination
All tasks offer a pagination endpoint. Because tasks can be added while
you are paginating, we work with a pointer-based approach using a task id.

If you want to start paginating from the most recent task to older tasks,
you can call (for example) `/cbct?sort_order=desc&limit=10`. Then, for the
next 10 items, you take the id last item of the previous result, and send
that along as the `from_id` like this:
`/cbct?sort_order=desc&limit=15&from_id=ckbulxexk0016xapiz8h3g2pn`.

## Known issues
- Currently, there is an issue with HTTP-v2 and curl. Please use
  the `--http1.1` command to circumvent it.

## Changelog
The changelog has moved and is available <a href="/docs/changelog">here</a>

## Patents
The following products are protected by patents in the U.S. and elsewhere, all
or some of which are third party intellectual property rights. This website is
provided to satisfy the virtual patent marking provisions of various jurisdictions
including the virtual patent marking provisions of the America Invents Act. The
following list of products may not be all inclusive, and other products not
listed here may be protected by one or more patents.

`US Patent Number 11,379,975, US Patent Number 11,568,533` - for Segmentation of 3D anatomical structures

`US Patent Number 11,455,774` - for Root Shape Prediction
 * OpenAPI spec version: 10.12.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  AcdListTasks,
  AcdRequestBody,
  AcdTask,
  BadRequestResponse,
  CreateAcdParams,
  CreateAcdUploadParams,
  ErrorResponse,
  ForbiddenResponse,
  GetAcdTasksParams,
  GoneResponse,
  NotFoundResponse,
  UnauthorizedResponse
} from '../model'




/**
 * @summary Gets all tasks for the current authenticated user
 */
export const getAcdTasks = (
    params?: GetAcdTasksParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AcdListTasks>> => {
    
    return axios.get(
      `/acd`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetAcdTasksQueryKey = (params?: GetAcdTasksParams,) => {
    return [`/acd`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAcdTasksQueryOptions = <TData = Awaited<ReturnType<typeof getAcdTasks>>, TError = AxiosError<ErrorResponse | UnauthorizedResponse>>(params?: GetAcdTasksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAcdTasks>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAcdTasksQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAcdTasks>>> = ({ signal }) => getAcdTasks(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAcdTasks>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAcdTasksQueryResult = NonNullable<Awaited<ReturnType<typeof getAcdTasks>>>
export type GetAcdTasksQueryError = AxiosError<ErrorResponse | UnauthorizedResponse>

/**
 * @summary Gets all tasks for the current authenticated user
 */
export const useGetAcdTasks = <TData = Awaited<ReturnType<typeof getAcdTasks>>, TError = AxiosError<ErrorResponse | UnauthorizedResponse>>(
 params?: GetAcdTasksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAcdTasks>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAcdTasksQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Starts automated crown generation on provided optical scan segmentation tasks
for the provided FDIs.
 * @summary Run automated crown generation
 */
export const createAcd = (
    acdRequestBody: AcdRequestBody,
    params: CreateAcdParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AcdTask>> => {
    
    return axios.post(
      `/acd`,
      acdRequestBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getCreateAcdMutationOptions = <TError = AxiosError<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAcd>>, TError,{data: AcdRequestBody;params: CreateAcdParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createAcd>>, TError,{data: AcdRequestBody;params: CreateAcdParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAcd>>, {data: AcdRequestBody;params: CreateAcdParams}> = (props) => {
          const {data,params} = props ?? {};

          return  createAcd(data,params,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateAcdMutationResult = NonNullable<Awaited<ReturnType<typeof createAcd>>>
    export type CreateAcdMutationBody = AcdRequestBody
    export type CreateAcdMutationError = AxiosError<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse>

    /**
 * @summary Run automated crown generation
 */
export const useCreateAcd = <TError = AxiosError<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAcd>>, TError,{data: AcdRequestBody;params: CreateAcdParams}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getCreateAcdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Starts automated crown generation on any optical scan segmentation in the STL format, for the specified FDI number
 * @summary Run automated crown generation
 */
export const createAcdUpload = (
    jawType: 'upper' | 'lower',
    fdi: string[],
    createAcdUploadBody: Blob,
    params?: CreateAcdUploadParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AcdTask>> => {
    
    return axios.post(
      `/acd/${jawType}/${fdi}`,
      createAcdUploadBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getCreateAcdUploadMutationOptions = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAcdUpload>>, TError,{jawType: 'upper' | 'lower';fdi: string[];data: Blob;params?: CreateAcdUploadParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createAcdUpload>>, TError,{jawType: 'upper' | 'lower';fdi: string[];data: Blob;params?: CreateAcdUploadParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAcdUpload>>, {jawType: 'upper' | 'lower';fdi: string[];data: Blob;params?: CreateAcdUploadParams}> = (props) => {
          const {jawType,fdi,data,params} = props ?? {};

          return  createAcdUpload(jawType,fdi,data,params,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateAcdUploadMutationResult = NonNullable<Awaited<ReturnType<typeof createAcdUpload>>>
    export type CreateAcdUploadMutationBody = Blob
    export type CreateAcdUploadMutationError = AxiosError<UnauthorizedResponse | ForbiddenResponse>

    /**
 * @summary Run automated crown generation
 */
export const useCreateAcdUpload = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAcdUpload>>, TError,{jawType: 'upper' | 'lower';fdi: string[];data: Blob;params?: CreateAcdUploadParams}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getCreateAcdUploadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Gets the task object for an ACD inference
 */
export const getAcd = (
    taskId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AcdTask>> => {
    
    return axios.get(
      `/acd/${taskId}`,options
    );
  }


export const getGetAcdQueryKey = (taskId: string,) => {
    return [`/acd/${taskId}`] as const;
    }

    
export const getGetAcdQueryOptions = <TData = Awaited<ReturnType<typeof getAcd>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAcd>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAcdQueryKey(taskId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAcd>>> = ({ signal }) => getAcd(taskId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAcd>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAcdQueryResult = NonNullable<Awaited<ReturnType<typeof getAcd>>>
export type GetAcdQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets the task object for an ACD inference
 */
export const useGetAcd = <TData = Awaited<ReturnType<typeof getAcd>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAcd>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAcdQueryOptions(taskId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Deletes a task
 */
export const deleteAcd = (
    taskId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/acd/${taskId}`,options
    );
  }



export const getDeleteAcdMutationOptions = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAcd>>, TError,{taskId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteAcd>>, TError,{taskId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAcd>>, {taskId: string}> = (props) => {
          const {taskId} = props ?? {};

          return  deleteAcd(taskId,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteAcdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAcd>>>
    
    export type DeleteAcdMutationError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

    /**
 * @summary Deletes a task
 */
export const useDeleteAcd = <TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAcd>>, TError,{taskId: string}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getDeleteAcdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Gets an individual structure in the desired format
 */
export const getAcdCrownStructure = (
    taskId: string,
    structureId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Blob>> => {
    
    return axios.get(
      `/acd/${taskId}/crown/${structureId}`,{
        responseType: 'blob',
    ...options,}
    );
  }


export const getGetAcdCrownStructureQueryKey = (taskId: string,
    structureId: string,) => {
    return [`/acd/${taskId}/crown/${structureId}`] as const;
    }

    
export const getGetAcdCrownStructureQueryOptions = <TData = Awaited<ReturnType<typeof getAcdCrownStructure>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(taskId: string,
    structureId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAcdCrownStructure>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAcdCrownStructureQueryKey(taskId,structureId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAcdCrownStructure>>> = ({ signal }) => getAcdCrownStructure(taskId,structureId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId && structureId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAcdCrownStructure>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAcdCrownStructureQueryResult = NonNullable<Awaited<ReturnType<typeof getAcdCrownStructure>>>
export type GetAcdCrownStructureQueryError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>

/**
 * @summary Gets an individual structure in the desired format
 */
export const useGetAcdCrownStructure = <TData = Awaited<ReturnType<typeof getAcdCrownStructure>>, TError = AxiosError<UnauthorizedResponse | ForbiddenResponse | NotFoundResponse | GoneResponse>>(
 taskId: string,
    structureId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAcdCrownStructure>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAcdCrownStructureQueryOptions(taskId,structureId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



